import * as React from "react"
import PrivateRoute from "../../../../components/privateRoute"
import ReduxWrapper from "../../../../redux/reduxWrapper"
import Detail from "../../../../components/SuperAdmin/Detail/detail"
import AdminPrivateRoute from "../../adminPrivateRoute"

const AdminDetail = props => {
  return <AdminPrivateRoute component={Detail} location={props.location} selectedContactId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<AdminDetail {...props} />} />
export default WrappedPage

export const Head = () => <title>Admin Detail</title>
