import React, { useState } from "react"
import AdminLayout from "../../SuperAdminLayout/adminLayout"
import "../../../css/admin_style.css"
import DetailChatBoxPopup from "./detailChatBoxPopup"
import Tag from "./tag"

const Detail = () => {
  const [chatBox, setChatBox] = useState(false),
    [generatePassword, setGeneratePassword] = useState(false)

  const [addTag, setAddTag] = useState(false),
    [newTag, setNewTag] = useState(""),
    [tempTags, setTempTags] = useState([]),
    [removeTagIndex, setRemoveTagIndex] = useState(null)

  return (
    <AdminLayout>
      <section className="my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="text-center">Login User Details</h1>

              <div className="autoPassGeneratorContainer">
                <Tag
                  addTag={addTag}
                  setAddTag={setAddTag}
                  newTag={newTag}
                  setNewTag={setNewTag}
                  tempTags={tempTags}
                  setTempTags={setTempTags}
                  removeTagIndex={removeTagIndex}
                  setRemoveTagIndex={setRemoveTagIndex}
                />
                <div className="row mb-3 align-items-center">
                  <label htmlFor="datacenter" className="col-sm-4 mb-0 text-start text-sm-end">
                    Off-Domain Publish
                  </label>
                  <div className="col-sm-8">
                    <select className="form-select" name="datacenter" defaultValue={"3"} id="datacenter">
                      <option value="">Select Data Center</option>
                      <option value="1">Publishing Disabled</option>
                      <option value="2">Cloudflare Site</option>
                      <option value="3">FTP Off-Domain</option>
                      <option value="4">Our Datacenter - Frankfurt</option>
                      <option value="5">Our Datacenter - USA NE</option>
                      <option value="6">Our Datacenter - USA SW</option>
                      <option value="7">Our Datacenter - Canada</option>
                      <option value="8">Our Datacenter - Singapore</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12 text-center autoPassGenerator">
                    Click here to change Login PW:{" "}
                    <button
                      className="btn btn-primary"
                      name="btnGeneratePassword"
                      id="btnGeneratePassword"
                      data-active="0"
                      onClick={() => setGeneratePassword(!generatePassword)}
                    >
                      <i className="bi bi-key-fill"></i>{" "}
                      {generatePassword ? "Reset Password" : "Generate Password"}
                    </button>
                  </div>
                </div>
                {generatePassword === true && (
                  <div className="row mb-3 newPasswordRow">
                    <div className="col-lg-12 text-center">
                      <div className="row row-cols-lg-auto g-2 align-items-center justify-content-center">
                        <div className="col-12">Password</div>
                        <div className="col-12">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="btnCopyPassInput"
                              defaultValue="123456"
                              readOnly="readOnly"
                            />
                            <button
                              className="btn btn-secondary btnCopyPass"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-original-title="Copy"
                              aria-label="Copy"
                            >
                              <i className="bi bi-files"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-12 text-center">
                    <button className="btn btn-primary" name="btnSaveMigrate">
                      <i className="bi bi-check2"></i> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table className="table table-striped table-bordered dataTable" cellSpacing="0" width="100%">
                  <thead>
                    <tr>
                      <th width="10%">ID</th>
                      <th width="20%">Name</th>
                      <th width="40%">Value</th>
                      <th width="30%">Meta</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>email_primary_1</td>
                      <td>lendermate+marketing@gmail.com</td>
                      <td>{`"type":"Primary","privacy":"p"`}</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>first_name</td>
                      <td>Roger</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>last_name</td>
                      <td>Vaughn</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="chatDockSwitch">
        <input
          type="checkbox"
          id="chatDockSwitch"
          onChange={e => setChatBox(e.target.checked)}
          checked={chatBox}
        />
        <label htmlFor="chatDockSwitch" className="checkmark"></label>
      </div>

      {chatBox === true && <DetailChatBoxPopup setChatBox={setChatBox} />}
    </AdminLayout>
  )
}

export default Detail
