import { navigate } from "gatsby"
import jwt_decode from "jwt-decode"

export const isBrowser = () => typeof window !== "undefined"

export const isLoggedIn = () => {
  return !!(isBrowser() && window.localStorage.getItem("jwt"))
}

export const logout = () => {
  window.localStorage.removeItem("jwt")
  window.localStorage.removeItem("refreshJwt")
  window.localStorage.removeItem("x-switch-user")
  navigate("/login")
  window.location.reload(true)
}

export const getAccessToken = () => {
  return window.localStorage.getItem("jwt")
}

export const getLoginUserId = () => {
  if (isLoggedIn()) {
    return window.localStorage.getItem("user_id")
  }

  return null
}

export const getSwitchUserEmail = () => {
  return window.localStorage.getItem("x-switch-user")
}
