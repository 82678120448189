import React from "react"
import { navigate } from "gatsby"
import { isBrowser, isLoggedIn } from "../services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (isBrowser()) {
    if (!isLoggedIn() && location.pathname !== `/login`) {
      if (!location.pathname.includes("/logout")) {
        window.localStorage.setItem("lastpath", location.pathname)
      }
      navigate(`/login`)
    } else {
      return <Component {...rest} />
    }
  }

  return <React.Fragment />
}

export default PrivateRoute
